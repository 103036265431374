<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "HeaderBar",
  props: {
    title: {
      type: String,
      default: "... loading ..."
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectOption(option){
      this.$emit("select-option", option);
    }
  },
})
</script>

<template>
  <header>
    <h1> {{ title }} </h1>
    <ul>
      <li v-for="option in options" :key="option.id" :class="{ selected : option.isSelected }" @click="selectOption(option)">
        {{ option.name }}
      </li>
    </ul>
  </header>
</template>

<style scoped lang="scss">
@import "../assets/colors.scss";
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $title-color;
    border-bottom: 1px solid $border-color;
    grid-area: 1 / 1 / 2 / 6;
    padding: 0 2rem;

    h1 {
      padding: 1.2rem 0;
      font-size: 2.5rem;
      font-weight: 600;
    }
    ul{
      display: flex;
      align-items: stretch;
      height: 100%;
      li{
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 0 0 2rem;
        transition: all 0.2s, color 0.4s ease-in-out, border-bottom-color 0.4s ease-in-out;
        font-weight: 600;
        font-size: 1.3rem;
        cursor: pointer;
        text-transform: uppercase;
        border-bottom: 0 solid transparent;

        &:hover{
          color: $accent-color;
        }
        &.selected{
          border-bottom: 3px solid $accent-color;
          color: $accent-color;
        }
      }
    }
  }

@media (max-width: 920px) {
  header {
    h1 {
      font-size: 2rem;
    }
    ul{
      display: none;
    }
  }
}
</style>