import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomePage from "@/views/HomeView/HomePage.vue";
import PersonnelPage from "@/views/HomeView/PersonnelPage.vue";
import UserPage from "@/views/HomeView/UserPage.vue";

import LoginPage from "@/views/LoginPage.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'home',
        name: 'homePage',
        component: HomePage,
        alias: '',
      },
      {
        path: 'personnel',
        name: 'personnelPage',
        component: PersonnelPage
      },
      {
        path: 'personnel/:id',
        name: 'userPage',
        component: UserPage
      },
    ]
  },

  {
      path: '/login',
      name: 'login',
      component: LoginPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
