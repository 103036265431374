<template>
  <div class="home">
    <side-bar></side-bar>
    <div class="main">
      <router-view/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/colors.scss";

.home {
  display: grid;
  grid-template-columns: 0.07fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  height: 100vh;
  width: 100vw;

  .main {
    overflow-x: hidden;
    overflow-y: auto;
    grid-area: 1 / 2 / 2 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

aside {

  grid-area: 1 / 1 / 2 / 2;
}

@media (max-width: 920px) {
  .home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .main {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
}

</style>

<script>
import SideBar from "@/components/SideBar.vue";
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    SideBar
  },
  beforeCreate() {
    console.log(this.$store.state.loggedIn)
    console.log(this.$store.state.user)
    if (!this.$store.state.loggedIn){
      this.$router.push("/login");
    }
    fetch("http://188.166.21.64:8080/api/profile/tags")
        .then(res => res.json())
        .then(data => {
          console.log(data)
          this.$store.commit("setTags", data);
        })
  }
}
</script>
