<script>
import {defineComponent} from 'vue'
import Header from "@/components/HeaderBar.vue";
import UserCard from "@/components/user/UserCard.vue";
import UserPopup from "@/components/user/UserPopup.vue";
import ActionsPopup from "@/components/user/ActionsPopup.vue";
import CreateUserPopup from "@/components/user/CreateUserPopup.vue";

export default defineComponent({
  name: "PersonnelPage",
  components: {
    CreateUserPopup,
    UserPopup,
    Header,
    UserCard,
    ActionsPopup
  },
  data() {
    return {
      title: "Personnel enregistré",
      options: [
        {id: 1, name: "TOUS", isSelected: true},
        {id: 2, name: "CASIER", isSelected: false},
        {id: 3, name: "ENQUÊTEUR", isSelected: false},
        {id: 4, name: "RECHERCHÉ", isSelected: false},
      ],
      loading: false,
      selectedOption: "1",
      users: [],
      showModal: false,
      activeUser: {},
      showAction: false,
      showCreateUser: false,
      page: 0,
    }
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option.id.toString()
      this.options.forEach(opt => opt.isSelected = opt.id === option.id);
    },
    showUser(user) {
      console.log("showing user")
      this.showModal = true;
      this.activeUser = user;
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loading = true;
      }
    },
  },
  created() {
    this.loading = true;
    fetch(`http://188.166.21.64:8080/api/profile/page/?page=${this.page}`,
    {
      method: "get",
      headers: {
        "token": this.$store.state.token,
      }
        })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        this.users = data;
        this.loading = false;
      })
  }
})
</script>

<template>
  <Header title="Personnel enregistré" @selectOption="selectOption" :options="options"/>
  <div class="container">
    <div class="header">
      <input type="text" name="E" id="a" placeholder="Rechercher">
      <span><b> {{ users.length }} </b> résultat(s)</span>
    </div>
    <div class="content" @scroll="onScroll">
      <UserCard v-for="user in users" :key="user.id" :user="user" @showUser="showUser"/>
      <i class="loading pi pi-spin pi-spinner" v-if="loading"></i>
      <i class="add pi pi-plus" @click="showCreateUser =true"></i>
    </div>
  </div>
  <Teleport to="#app">
    <!-- use the modal component, pass in the prop -->
    <user-popup :show="showModal" :user="activeUser" @openAction="showAction = true" @close="showModal = false">

    </user-popup>
    <actions-popup @close="showAction = false" :show="showAction">

    </actions-popup>
    <CreateUserPopup @close="showCreateUser = false" :show="showCreateUser">

    </CreateUserPopup>

  </Teleport>
</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";


.container {
  border-radius: 15px 15px 0 0;
  width: calc(100% - 9rem);
  height: calc(100% - 4rem);
  background-color: $tertiary-color;
  margin: 2rem 2rem 0 2rem;
  padding: 2rem 2.5rem 0 2.5rem;
  grid-area: 2 / 1 / 6 / 6;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.03fr 1fr;

  .header {
    grid-area: 1 / 1 / 2 / 6;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    input {
      width: 20%;
      padding: 0.5rem;
      border-radius: 8px;
      border: 3px solid #2C2F35;
      background-color: $secondary-color;
      color: $text-color;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
    }

    span {
      padding-bottom: 10px;
      font-weight: 400;
      color: $text-color;

      b {
        font-weight: 600;

        color: $accent-color;
      }

    }
  }

  .content {
    overflow: auto;
    margin-top: 2rem;
    grid-area: 2 / 1 / 6 / 6;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: normal;
    align-items: flex-start;
    align-content: flex-start;
    gap: 3rem;

    .loading {
      font-size: 5rem;
      font-weight: 600;
      color: $accent-color;
      position: fixed;
      bottom: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .add{
      position: absolute;
      font-size: 2.3rem;
      font-weight: 600;
      color: $title-color;
      padding: .6rem;
      background-color: $primary-color;
      border-radius: 50%;
      bottom: 3rem;
      right: 3rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      z-index: 100;

      &:hover {
        background-color: $accent-color;
      }

    }
  }
}

@media (max-width: 1456px) {
  .content {
    gap: 2rem !important;
  }
}

@media (max-width: 600px) {
  .container {
    border-radius: 15px 15px 0 0;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    background-color: $tertiary-color;
    margin: 2rem 1rem 0 1rem;
    padding: 2rem 1rem 0 1rem;

    .header {
      input {
        width: 50%;
      }
    }
  }
}

</style>