<script>
import TagsBar from "@/components/tagsBar.vue";

export default {
  name: "CreateUserPopup",
  components: {TagsBar},
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userData: {
        id: 9832,
        firstname: "Christopher",
        lastname: "Hawkins",
        discord : {
          id: "123456789",
        },
        grade: "Sous-Directeur",
        tags: [1, 2],
        image: "https://cdn.discordapp.com/attachments/954049258204594208/1213254389603639306/1840612-image-profil-icon-male-icon-human-or-people-sign-and-symbol-vector-gratuit-vectoriel.png?ex=65f4ce33&is=65e25933&hm=e2c35523276f98b722cc81781df3152eb64efba1d5e7a01fac47606934cd90b3&",
        department: [1],
        iteration: 0,
      },
      loading: false,
    }
  },
  methods: {
    uploadImage(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.userData.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    modifyTag(tag, type, toRemove) {
      if (toRemove) {
        this.userData[type === 'tags' ? 'tags' : 'department'] = this.userData[type === 'tags' ? 'tags' : 'department'].filter(t => t !== tag)

      } else {
        this.userData[type === 'tags' ? 'tags' : 'department'].push(tag)
        this.userData[type === 'tags' ? 'tags' : 'department'].sort((a, b) => a - b)
        this.userData[type === 'tags' ? 'tags' : 'department'] = this.userData[type === 'tags' ? 'tags' : 'department'].filter((item, index) => this.userData.tags.indexOf(item) === index)

      }
    },
    send() {
      this.loading = true
      fetch('http://188.166.21.64:8080/api/profile/new',
        {
          method: "post",
          headers: {
            "token": this.$store.state.token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({userProfil: this.userData})
        }).then(
        res => res.json()
      ).then(data => {
          console.log(data)
          }
      )
    }
  }
}
</script>

<template>
  <Transition name="fade">
    <div class="newUser" v-if="show">
      <div class="content">
        <i class="exit pi pi-times"
           @click="$emit('close')"></i>
        <aside>
          <div class="image-upload">
            <label for="file-input">
              <img :src="userData.image" alt="user image">
            </label>
            <input id="file-input" type="file" accept=".png,.jpg" @change="uploadImage">
          </div>
          <h3>Tags</h3>
          <tags-bar :tags="userData.tags" :add="true" type="tags" :styles="['medium']" @modify-tag="modifyTag"></tags-bar>
          <br>
          <h3>Départements</h3>
          <tags-bar :tags="userData.department" :add="true" type="deps" :styles="['medium', 'card-color']"
                    @modify-tag="modifyTag"></tags-bar>
        </aside>
        <div class="main">
          <h3>Créer un nouveau profil</h3>
          <form action="">
            <div class="form-group">
              <label for="firstName">Prénom</label>
              <input type="text"
                     name="firstName"
                     required maxlength="15"
                     minlength="3" id="firstName"
                     onkeydown="return /[a-zA-Z]/i.test(event.key)"
                     v-model="userData.firstname">
            </div>
            <div class="form-group">
              <label for="lastName">Nom</label>
              <input type="text" maxlength="15" required min="3" name="lastName" id="lastName"
                     v-model="userData.lastname">
            </div>
            <div class="form-group">
              <label for="discord">Grade</label>
              <input type="text" required name="grade" id="rank" v-model="userData.grade">
            </div>
            <div class="form-group">
              <label for="discord">Identifiant</label>
              <input type="number" required min="1000" max="99999" name="id" id="id" v-model="userData.id">
            </div>
            <div class="form-group">
              <label for="discord">Discord <span>(Si précisé, le profil se transformer en utilisateur.)</span></label>
              <input type="text" name="discord" id="discord" v-model="userData.discord.id">
            </div>
            <div class="button" @click="send()">Envoyer <i class="pi pi-send"></i></div>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";

.newUser {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: $secondary-color;
    border-radius: 15px;
    overflow: hidden;

    .exit{
      position: absolute;
      top: 1rem;
      right: 10px;
      font-size: 1.5rem;
      cursor: pointer;
      transition: color 200ms;
      &:hover {
        color: $red-color;
      }
    }

    aside {
      width: 15rem;
      background-color: $tertiary-color;
      padding: 1.5rem 0 0 1.5rem;

      .image-upload {
        position: relative;
        width: 12rem;
        height: 12rem;
        border-radius: 10%;
        overflow: hidden;
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }

      h3 {
        font-size: 1.05rem;
        font-weight: 300;
        color: $title-color;
        margin: 0.3rem 0 .5rem;
      }

      .tags-bar {
        margin-bottom: 1rem;
      }
    }

    .main {
      padding: 0.8rem 2rem 2rem;

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0.3rem 0 1.3rem;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          label {
            font-size: 1.1rem;
            font-weight: 500;

            span {
              font-size: 0.75rem;
              font-weight: 300;
              color: $sub-text-color;
              margin-left: 0.2rem;
            }
          }

          input, select, textarea {
            display: block;
            padding: 0.5rem;
            border-radius: 8px;
            border: 1px solid $border-color;
            background-color: $secondary-color;
            color: $text-color;
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            color-scheme: dark;
            text-transform: capitalize;

            &::placeholder {
              color: $sub-text-color;
            }

            &:focus {
              outline: none;
              border-color: $accent-color;
            }

          }

          textarea {
            resize: vertical;
            max-height: 400px;
            min-height: 50px;

            &::-webkit-scrollbar {
              background-color: transparent;
              height: 6px;
              width: 5px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $border-color;
              transition: all 200ms;

              &:hover {
                background-color: $secondary-color;
              }

              &:active {
                background-color: $accent-color;
              }
            }
          }
        }

        .button {
          text-align: center;
          cursor: pointer;
          padding: 0.5rem;
          border-radius: 8px;
          border: 1px solid $border-color;
          background-color: $tertiary-color;
          color: $text-color;
          font-size: 1rem;
          font-weight: 400;
          margin-top: 1rem;
          cursor: pointer;
          transition: background-color 200ms;

          i {
            margin-left: 0.5rem;
            font-weight: 100;
          }

          &:hover {
            background-color: $accent-color;
          }

          &:disabled {
            background-color: $sub-text-color;
            cursor: not-allowed;
          }
        }
      }
    }
  }


}
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }


.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
