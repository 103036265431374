<script>
import {defineComponent} from 'vue'
import TagsBar from "@/components/tagsBar.vue";
export default defineComponent({
  name: "UserCard",
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      showModal : false,
      image: "https://cdn.discordapp.com/attachments/954049258204594208/1213254389603639306/1840612-image-profil-icon-male-icon-human-or-people-sign-and-symbol-vector-gratuit-vectoriel.png?ex=65f4ce33&is=65e25933&hm=e2c35523276f98b722cc81781df3152eb64efba1d5e7a01fac47606934cd90b3&"
    }
  },
  components: {
    TagsBar
  }
})
</script>

<template>
  <div class="user-card" @click="$emit('showUser', user)">
    <div class="top">
      <img :src="image" alt="">
      <div class="tags">
        <tags-bar :tags="user.tags" :styles="['flex-end','medium']" type="tags" />
      </div>
    </div>
    <div class="middle">
      <h3>{{user.lastname}} <span> {{ user.firstname }} </span></h3>
      <h4>{{ user.grade }}</h4>
      <div class="deps">
        <tags-bar :tags="user.department" :styles="['primary-color', 'columns']" type="deps" />
      </div>
    </div>
  </div>


</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";

.user-card {
  width: 20.5rem;
  height: 20rem;
  display: grid;
  grid-template-rows: .6fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "middle";
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: $secondary-color;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    img {
      width: 6rem;
      height: 6rem;
      border-radius: 20%;
      object-fit: cover;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-end;
      gap: 0.5rem;
      span
      {
        font-size: 0.8rem;
        padding: 0.3rem;
        border-radius: 0.5rem;
        background-color: $primary-color;
        color: $text-color;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $text-color;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0.3rem 0;
      color: $title-color;
      span {
        font-weight: 400;
        color: $text-color;

      }
    }
    h4 {
      font-size: 1.2rem;
      font-weight: 400;
      color: $sub-text-color;
    }

    .deps {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      p {
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: $primary-color;
        color: $text-color;
        }
      }
    }
  &:hover{
    border: 1px solid $accent-color;
  }
}


</style>