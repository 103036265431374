<script>
import {defineComponent} from 'vue'
import TagsBar from "@/components/tagsBar.vue";
export default defineComponent({
  name: "UserPopup",
  components: {TagsBar},
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      repeat : [0,1,3],
      image: "https://cdn.discordapp.com/attachments/954049258204594208/1213254389603639306/1840612-image-profil-icon-male-icon-human-or-people-sign-and-symbol-vector-gratuit-vectoriel.png?ex=65f4ce33&is=65e25933&hm=e2c35523276f98b722cc81781df3152eb64efba1d5e7a01fac47606934cd90b3&"
    }
  },
  methods: {
    toggleModal() {
      console.log("toggling modal");
      this.$emit("close");
    }
  }
})
</script>

<template>
  <Transition name="popup">

  <div class="user-popup" v-if="show" @keyup="toggleModal" >
    <i @click="toggleModal" class="pi pi-times"></i>
    <div class="content">
      <div class="header">
        <div class="top">
          <img :src="image" alt="e">
          <div class="tags">
            <tags-bar :tags="user.tags" :styles="['flex-end','big']" type="tags" />
          </div>
        </div>
        <div class="bottom">
          <h3>{{ user.lastname }} <span> {{ user.firstname }} </span></h3>
          <h4>{{ user.grade }}</h4>
          <div class="deps">
            <tags-bar :tags="user.departements" :styles="['primary-color', 'columns']" type="deps" />
          </div>
        </div>
      </div>
      <div class="body">
        <div class="title">
          <h3>Dernières Activités</h3>
          <span @click="$emit('openAction')"><a class="pi pi-external-link"> </a>Ajouter</span>
        </div>
        <div class="last-activities">
          <div class="activity" v-for="ib in repeat" :key="ib">
            <h4>Mise à pied</h4>
            <div class="main">
              <p>Christopher Hawkins a tiré volontairement sur le directeur de l’installation avec une AK-47 depuis l’auditorium. Il est en attente de convocation par le D.O.J. Afairre à suivre</p>

            </div>
            <footer><span>Par Mjr. Perrin Max</span> <span>Le 21/23 à 19h03</span></footer>
          </div>
        </div>
      </div>
      <div class="bottom">

      </div>
    </div>
  </div>
  </Transition>
</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";

.user-popup{
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.51);
  backdrop-filter: blur(3.4px);
  i {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
    font-weight: 700;
    font-size: 2rem;
    z-index: 999;
    cursor: pointer;
    transition: color 200ms;
    &:hover{
      color: red;
    }
  }
  .content{
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100vh;
    overflow-y: auto;
    background-color: $primary-color;
    .header{
      padding: 2rem ;

      background: repeating-linear-gradient(
              45deg,
              $secondary-color 0,
              $secondary-color 5px,
              $tertiary-color 5px,
              $tertiary-color 10px
      );

      .top {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        img {
          width: 8rem;
          height: 8rem;
          border-radius: 20%;
          object-fit: cover;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-end;
          gap: 0.5rem;
          span
          {
            font-size: 1rem;
            padding: 0.3rem;
            border-radius: 0.5rem;
            background-color: $primary-color;
            color: $text-color;
          }
        }
      }
      .bottom{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: $text-color;
        h3 {
          font-size: 1.5rem;
          font-weight: 700;
          margin: 0.3rem 0;
          color: $title-color;
          span {
            font-weight: 400;
            color: $text-color;
          }
        }
        h4 {
          font-size: 1.3rem;
          font-weight: 700;
          color: $sub-text-color;
        }

        .deps {
          margin-top: 1rem;
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          p {
            padding: 0.5rem;
            border-radius: 0.5rem;
            background-color: $primary-color;
            color: $text-color;
          }
        }
      }
    }
    .body {
      margin: 1rem 2rem ;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 1.4rem;
          font-weight: 600;
          margin: 0.5rem 0;
          color: $title-color;
        }
        span{
          font-size: 1rem;
          font-weight: 600;
          color: $sub-text-color;
          cursor: pointer;
          transition: color 200ms;
          &:hover{
            color: $accent-color;
          }
          a{
            margin-right: 10px;
          }
        }
      }
      .last-activities {
        display: flex;
        gap: 1rem;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 0.2rem;

        .activity{
          border-left: 5px solid #ff6550;
          background-color: $card-color;
          padding: 1rem 0.8rem;
          border-radius: 5px;
          min-width: 280px;
          width: 280px;
          height: 94px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 3fr 1fr;
          grid-row-gap: 0.4rem;
          h4 {
            font-size: 0.96rem;
            font-weight: 600;
            color: $title-color;
            grid-area: 1 / 1 / 2 / 2;
          }
          p{
            color: $text-color;
            font-size: 0.77rem;
            overflow: hidden;
            display: -webkit-box;
            line-height: 14px;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;}
          footer{
            position: relative;
            display: flex;
            justify-content: space-between;
            grid-area: 3 / 1 / 4 / 2;
            span{
              font-size: 0.8rem;
              color: $sub-text-color;
            }
          }
        }
      }
    }
  }
}
.popup-enter-active,
.popup-leave-active {
  transition: all 0.3s ease;
}

.popup-enter-from {
  opacity: 0;
  .user-popup{
  }
}

.popup-leave-to {
  opacity: 0;
  .user-popup{
  }
}
@media screen and (max-width: 1400px) {
  .user-popup{
    .content{
      width: 40%;
      min-width: 400px;
    }
  }
}

@media screen and (max-width: 920px) {
  .user-popup{
    .content{
      width: 100%;
    }
  }
}

</style>
