<script>
import router from "../router/index";

export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    login() {
      console.log("login");
      if (this.username === "") {
        alert("Merci de remplir votre nom d'utilisateur");
        return;
      }

      this.isLoading = true;
      this.$store.commit("setUserid", this.username);
      const access_token = this.$store.state.token;
      if (access_token){
        console.log("access_token", access_token);
        fetch(`http://188.166.21.64:8080/api/profile/login?token=${access_token}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
            .then((res) => res.json())
            .then((data) => {
              this.isLoading = false;
              if (data.error) {
                alert(data.error);
                this.$store.commit("logout");
              } else if(data) {
                this.$store.commit("login", data);
                router.push("/");
              }
            });
      } else {
        window.location.href =
          "https://discord.com/oauth2/authorize?client_id=1203009039496126474&response_type=code&redirect_uri=http%3A%2F%2Fslove-secu.dynv6.net%2Flogin&scope=identify+guilds";
      }
    },
  },
  mounted() {
    console.log(this.$store.state.loggedIn)
    console.log(this.$store.state.user)
    const username = this.$store.state.userid;
    if (username) {
      console.log("username", username);
      this.username = username;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("code", code);

    if (code && username) {
      this.isLoading = true;
      fetch(`http://188.166.21.64:8080/api/profile/token/?id=${username}&code=${code}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            if (data.error) {
              alert(data.error);
            }
          }
          if (data.access_token) {
            this.isLoading = false;
            this.$store.commit("setToken", data.access_token);
            this.login();
          }
        });
    }
  },
};
</script>

<template>
  <main>
    <div class="header">
      <h1>B.S.I.</h1>
      <h3>Bureau de Surveillance et d’Investigation</h3>
    </div>
    <div class="forms">
      <input
        type="text"
        @keyup.enter="login"
        placeholder="Identifiant BSI"
        v-model="username"
        required
      />
      <button
        type="button"
        :disabled="isLoading"
        @click="login"
      >
        <i class="pi pi-send" v-if="!isLoading"></i>
        <a v-if="!isLoading"> Se connecter </a>
        <i
          class="pi pi-circle-on pi-spin pi-spinner"
          :class="{ loading: isLoading }"
          v-if="isLoading"
        >
        </i>
        <a v-if="isLoading"> Chargement... </a>
      </button>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  height: 100%;
  width: 100%;
  background: radial-gradient(116.48% 50% at 0% 50%, #0b1733 0%, #000510 100%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  .header {
    margin-left: -6%;
    h1 {
      font-size: 8rem;
      font-weight: 900;
      color: white;
    }
    h3 {
      color: white;
      font-size: 2.5rem;
      font-style: italic;
      font-weight: 300;
      line-height: normal;
    }
  }
  .forms {
    margin-right: -6%;
    display: flex;
    width: 25%;
    flex-direction: column;
    align-items: flex-end;
    input {
      background-color: transparent;
      display: inline-block;
      padding: 0 16px 0 12px;
      width: 100%;
      height: 40px;
      line-height: 30px;
      border: 1px solid #dddbda;
      border-radius: 4px;
      color: white;
      text-align: end;
      transition: border 0.1s linear, background-color 0.1s linear;
      &:focus {
        border-color: #1b96ff;
        box-shadow: rgb(1, 118, 211) 0 0 3px 0;
      }
      &::placeholder {
        text-align: start;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      padding: 0 16px;
      height: 32px;
      background-color: white;
      border: none;
      border-radius: 4px;
      &:disabled {
        background-color: #d3d3d3;
      }
      i {
        font-size: 1.2rem;
        color: #0b1733;
        margin-right: 10px;

      }
      a {
        font-size: 1.2rem;
        font-weight: 500;
        text-decoration: none;
      }
      span,
      a {
        color: #0b1733;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
