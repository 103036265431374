<script>
import {defineComponent} from 'vue'

/*
const tagsList = [
  {name: "Guest", color: "E17A03", id: 0}, //0
  {name: "Casier", color: "24FF00", id: 1}, //1
  {name: "Recherché", color: "0D5FD7", id: 2}, //2
  {name: "Développeur", color: "E7D21B", id: 3}, //3
  {name: "Sécurité", color: "E12B03", id: 4}, //4
]
*/

const depsList = [
  {name: "Corps d'encadrement", icon: 'eye', color: "900000"}, //0
  {name: "Équipe de développement", icon: 'code', color: "1dc9c9"}, //1
  {name: "Équipe de sécurité", icon: 'shield', color: "959595"}, //2
]
export default defineComponent({
  name: "tagsBar",
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    type: {
      type: String
    },
    add: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagsList : this.$store.state.tags,
      depsList,
      popup: {
        x: 0,
        y: 0,
        show: false,
        type: '',
        unlisted: [],
      }
    }
  },
  methods: {
    addTag(event) {
      if (!this.add) {
        return
      }
      this.popup.unlisted = []
      if (this.type === 'tags') {
        for (let number in this.tagsList){
          if (!this.tags.includes(Number(number))) {
            this.popup.unlisted.push(number)
          }
        }
      } else {
        /*for (let number in this.depsList) {
          if (!this.tags.includes(number)) {
            this.popup.unlisted.push(number)
          }
        }*/
      }
      console.log(this.popup.unlisted)
      this.popup.x = event.pageX - 5;
      this.popup.y = event.pageY - 5;
      this.popup.show = true;
    },
    popupleave() {
      this.popup.show = false;
    },
    clickTag(tag, toRemove = false) {
      if (!this.add) {
        return
      }
      if (toRemove) {
        this.$emit('modifyTag', tag, this.type, toRemove)
        this.popup.unlisted.push(tag)
      } else {
        this.$emit('modifyTag', tag, this.type)
        this.popup.unlisted = this.popup.unlisted.filter(t => t !== tag)
      }

    }
  }
})
</script>

<template>

  <div class="tags-bar" v-if="type === 'tags'" :class="styles">
    <span v-for="tag in tags"
          @click="clickTag(tag, true)"
          :class="[add ? 'pointer' : '']"
          :style="{'background-color': `#${tagsList[tag].color}44`, 'color': `#${tagsList[tag].color}`}"
          :key="tag">
            {{ tagsList[tag].name }}
    </span>
    <a v-if="add" class="pi pi-plus" @click="addTag"></a>
  </div>

  <div class="deps-bar" v-if="type === 'deps'" :class="styles">
    <span v-for="tag in tags"
          @click="clickTag(tag, true)"
          :class="[add ? 'pointer' : '']"
          :key="tag">
      <i :class="['pi', `pi-${depsList[tag].icon}`]"
         :style="{'color': `#${depsList[tag].color}`}">
      </i> {{ depsList[tag].name }}
    </span>
    <a v-if="add" class="pi pi-plus" @click="addTag"></a>
  </div>

  <div class="tag-popup" v-if="popup.show" :style="{'left': `${this.popup.x}px`, 'top': `${this.popup.y}px`}"
       @mouseleave="popupleave">
    <h3>Ajouter un tag</h3>
    <div class="tags" v-if="type === 'tags'">
      <span v-for="tag in popup.unlisted" @click="clickTag(tag, false)"
            :style="{'background-color': `#${tagsList[tag].color}44`, 'color': `#${tagsList[tag].color}`}"
            :key="tagsList[tag].name">{{ tagsList[tag].name }}</span>
    </div>
    <div class="deps" v-if="type === 'deps'">
      <span v-for="tag in popup.unlisted" @click="clickTag(tag, false)" :key="depsList[tag].name"><i
          :class="['pi', `pi-${depsList[tag].icon}`]"
          :style="{'color': `#${depsList[tag].color}`}"></i>{{ depsList[tag].name }}</span>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../assets/colors.scss";

.tags-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  span, a {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0.3rem;
    border-radius: 0.5rem;

    &.pointer {
      cursor: pointer;

    }
  }

  a {
    margin-right: 0.5rem;
    background-color: $primary-color;
    cursor: pointer;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.medium {
    span, a {
      font-size: 0.8rem;
      padding: 0.2rem;
      border-radius: 0.5rem;
    }
  }
}


.deps-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;

  &.columns {
    flex-direction: column;
  }

  &.primary-color {
    span {
      background-color: $primary-color;
    }
  }

  &.card-color {
    span {
      background-color: $card-color;
    }
  }

  span, a {
    text-align: center;
    background-color: $secondary-color;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: $text-color;
    align-items: center;

    &.pointer {
      cursor: pointer;

      &:hover {
        background-color: $red-color;
      }
    }

    i {
      font-weight: 200;
      margin-right: 0.5rem;
    }
  }

  a {
    display: flex;
    text-align: center;
    cursor: pointer;
    margin-right: 0.5rem;
    background-color: $primary-color;
  }

  &.medium {

    span, a {
      font-size: 0.8rem;
      padding: 0.2rem;
      border-radius: 0.5rem;
    }
  }
}

.tag-popup {
  position: absolute;
  background-color: $secondary-color;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .tags {
    display: flex;
    width: 2rem;
    flex-wrap: wrap;
    gap: 0.5rem;

    span {
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.8rem;
      padding: 0.2rem;
      border-radius: 0.5rem;
    }
  }

  .deps {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span {
      cursor: pointer;
      text-align: center;
      background-color: $secondary-color;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 0.3rem;
      border-radius: 0.5rem;
      color: $text-color;
      align-items: center;

      i {
        font-weight: 200;
        margin-right: 0.5rem;
      }
    }
  }

}

</style>