<template>
  <router-view/>
  <span id="credit">Développé avec ❤️ par <a href="https://github.com/Just-Yenzo" target="_blank">Just_</a>, <a href="">Flo</a> & <a href="">Orgea</a></span>
</template>

<style lang="scss">
@import "assets/reset.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "primeicons/primeicons.css";
@import "assets/colors.scss";


#app {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: $background-color;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main";
  #credit{
    z-index: 101;
    color: $sub-text-color;
    position: absolute;
    bottom: 1rem;
    right: 3rem;
    font-size: 0.8rem;
    font-weight: 200;
    cursor: default;
    a{
      text-decoration: none;
      color: $accent-color;
      font-weight: 400;
      transition: color 200ms;
      &:hover{
        color: #3f8eff;
      }
    }
  }
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px !important;
  height: 4px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $secondary-color;
  transition: all 200ms;
  &:hover{
    background-color: $border-color;
  }
  &:active{
    background-color: $accent-color;
  }

}
</style>
<script setup>
</script>