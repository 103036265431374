<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ActionsPopup",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    presetAction:{
      type: String,
      default: "1"
    }
  },
  data(){
    return {
      action: this.presetAction,
      today: new Date().toISOString().split("T")[0],
      motif: "",
      expiration: this.today,
      type: "1"
    }
  },
  watch: {
    show(){
      if(this.show){
        this.action = this.presetAction;
        this.motif = "";
        this.expiration = this.today;
        this.type = "1";
      }
    }
  },
  methods: {

  }
})
</script>

<template>
  <Transition name="fade">
    <div class="action" v-if="show" >
      <div class="content">
        <header><span><a class="pi pi-times" @click="$emit('close')"></a>Actions</span>
          <select v-model="action">
            <option value="1">Ajouter une sanction</option>
            <option value="2">Modifier l'utilisateur</option>
            <option value="3">Ajouter une ammende</option>
          </select>
        </header>
        <div class="main">
          <div v-if="action === '1'">
            <h3>Ajouter une sanction</h3>
            <form action="">
              <div class="form-group">
                <label for="motif">Motif</label>
                <textarea name="motif" id="motif" cols="30" rows="10" v-model="motif"></textarea>
              </div>
              <div class="form-group">
                <label for="duree">Expiration</label>
                <input type="date" name="duree" id="duree" :min="today" v-model="expiration">
              </div>
              <div class="form-group">
                <label for="type">Type</label>
                <select name="type" id="type" v-model="type">
                  <option value="1">Mise à pied</option>
                  <option value="2">Avertissement</option>
                  <option value="3">Licenciement</option>
                </select>
              </div>
              <div class="form-group">
                <button v-on:click.prevent="validate" :disabled="(!(motif && expiration && type))" @click="console.log('alerte E')">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";
.action {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 999;
  .content{
    overflow: hidden;
    background-color: $primary-color;
    width: 35rem;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    border: $border-color 2px solid;
    border-radius: .4rem;
    header{
      background-color: $secondary-color;
      height: 1rem;
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 1.2rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        a{
          margin-right: 0.4rem;
          cursor: pointer;
          transition: color 200ms;
          &:hover{
            color: red;
          }
        }
      }
      select{
        padding: 0.3rem;
        border-radius: 8px;
        border: 1px solid $border-color;
        background-color: $secondary-color;
        color: $text-color;
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
      }
    }
    .main{
      padding: 0.8rem 2rem 2rem;

      h3{
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0.3rem 0 0.9rem;
      }
      form{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .form-group{
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          label{
            font-size: 1.2rem;
            font-weight: 500;
          }
          input, select, textarea{
            padding: 0.5rem;
            border-radius: 8px;
            border: 1px solid $border-color;
            background-color: $secondary-color;
            color: $text-color;
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            color-scheme: dark;
          }
          textarea{
            resize: vertical;
            max-height: 400px;
            min-height: 50px;
            &::-webkit-scrollbar {
              background-color: transparent;
              height: 6px;
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $border-color;
              transition: all 200ms;
              &:hover{
                background-color: $secondary-color;
              }
              &:active{
                background-color: $accent-color;
              }
            }
          }
          button{
            padding: 0.5rem;
            border-radius: 8px;
            border: 1px solid $border-color;
            background-color: $secondary-color;
            color: $text-color;
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            cursor: pointer;
            transition: background-color 200ms;
            &:hover{
              background-color: $accent-color;
            }
            &:disabled{
              background-color: $sub-text-color;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>