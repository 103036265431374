<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SideBar",
  data() {
    return {
      show: false
    }
  },
})
</script>

<template>
  <i class="pi" :class="{ 'pi-times': show, 'pi-bars': !show}" @click="this.show = !this.show"></i>

  <aside :class=" {show} ">
    <img src="../assets/logo.png" alt="logo">
    <ul>
      <router-link class="pi pi-home" to="/"/>
      <router-link class="pi pi-briefcase" to="/personnel"/>
    </ul>
  </aside>
</template>

<style scoped lang="scss">
@import "../assets/colors.scss";

i {
  display: none;
  font-size: 1rem;
  font-weight: 600;
  color: $text-color;
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 10px;
  z-index: 100;

}

aside {
  max-width: 7rem;
  min-width: 6rem;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    top: 10px;
    position: absolute;
    width: 90px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      text-decoration: none;
      margin: 1rem 0;
      font-size: 3rem;
      color: $primary-color;
      cursor: pointer;
      padding: 0.8rem;
      transition: color 0.3s ease, border-radius 0.3s ease, background-color 0.3s ease;
      border-radius: 0.5rem;

      &:hover, &.router-link-active {
        color: $accent-color;
      }

      &.router-link-active {
        background-color: rgba(0, 104, 255, 0.39);
        border-radius: 1rem;
      }
    }
  }
}

@media (max-width: 920px) {
  i {
    display: block;
  }
  aside {
    display: none;
  }
  aside.show {
    display: flex;

    img {
      top: 40px;
    }

    max-width: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(26, 29, 36, 0.38);
    backdrop-filter: blur(6px);
  }
}


</style>