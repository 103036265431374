<script>
import {defineComponent} from 'vue'
import Header from "@/components/HeaderBar.vue";

export default defineComponent({
  name: "HomePage",
  components: {Header}
})
</script>

<template>
  <Header title="Accueil"></Header>
</template>

<style scoped lang="scss">

</style>