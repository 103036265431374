<script>
import {defineComponent} from 'vue'
// const iterationName = ["À titre officiel uniquement", "Confidentiel", "Restreint", "Secret", "Top Secret", "Thaumiel"]
import tagsBar from "@/components/tagsBar.vue";
const iterationName = {
  0: {
    name: "À titre officiel uniquement",
    color: "#03f468"
  },
  1: {
    name: "Confidentiel",
    color: "#04aef8"
  },
  2: {
    name: "Restreint",
    color: "#9800f5"
  },
  3: {
    name: "Secret",
    color: "#f79407"
  },
  4: {
    name: "Top Secret",
    color: "#961b00"
  },
  5: {
    name: "Thaumiel",
    color: "#010101"
  }
}

export default defineComponent({
  name: "UserPage",
  data (){
    return {
      iterationName,
      options: [
        {id: 1, name: "SANCTIONS", isSelected: true},
        {id: 2, name: "CASIER", isSelected: false},
      ],
      selectedOption: "1",
      userData: {
        id: 0,
        firstName: "Christopher",
        lastName: "Hawkins",
        discord: "",
        grade: "Responsable Infrastructure",
        tags: [1,3,2],
        image: "https://cdn.discordapp.com/attachments/1198269222833356903/1208364210376155166/PP.png?ex=65e303de&is=65d08ede&hm=59a35d9c47303e684a26ec03b41d6ce61ca505dc606cffd20f58f9cd6dd3f74b&",
        departements: [1,2],
        iteration: 0,
      },
      loading: false,
    }
  },
  components: {
    tagsBar
  },
  methods: {
    selectOption(option){
      this.selectedOption = option.id.toString()
      this.options.forEach(opt => opt.isSelected = opt.id === option.id);
    },
    fetchUser(){
      console.log("fetching user")
      console.log(this.$route.params.id)
      this.loading = true;
      fetch(`https://json
placeholder.typicode.com/users/${this.$route.params.id}`)
        .then(res => res.json())
        .then(data => {
          this.userData = data;
          this.loading = false;
        })
    }
  },
  created(){
    // this.fetchUser();
  }

})
</script>

<template>

  <div class="no-responsive">
    Cette page n'est pas encore responsive... <br> <br> Pour accéder à celle-ci, veuillez utiliser un écran plus grand.
  </div>

  <i class="loading pi pi-spin pi-spinner" v-if="loading"></i>

  <div class="container" v-else>
    <header>
      <div class="left">
        <img :src="userData.image" alt="Photo De Profil">
        <div>
          <h1>{{ userData.lastName }} <span>{{ userData.firstName }}</span></h1>
          <h2>{{ userData.grade }}</h2>
          <div class="tags">
            <tags-bar :tags="userData.tags" type="tags"/>
          </div>
          <div class="deps">
            <tags-bar :tags="userData.departements" type="deps"/>
          </div>
        </div>
      </div>
      <div class="right">
        <p class="iteration">Iteration : <span :style="{'backgroundColor': iterationName[userData.iteration].color}">{{ iterationName[userData.iteration].name }}</span></p>
        <p class="id">ID : <span>{{userData.id}}</span>  </p>
      </div>
    </header>
    <div class="content">
      <div class="header">
        <ul>
          <li v-for="option in options" :key="option.id" :class="{ selected : option.isSelected }" @click="selectOption(option)">
            {{ option.name }}
          </li>
        </ul>
        <input type="search" placeholder="Rechercher...">
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../../assets/colors.scss";
.no-responsive{
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 600;
  color: $text-color;
  text-align: center;
  i{
    font-size: 5rem;
    font-weight: 600;
    color: $accent-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

i {
  font-size: 5rem;
  font-weight: 600;
  color: $accent-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container{
  height: calc(100vh - 2rem);
  display: grid;
  margin: 2rem 2rem 0 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 1fr;
  grid-column-gap: 0;
  grid-row-gap: 1rem;
  header {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: space-between;
    .right{
      text-align: right;
      p{
        color: $accent-color;
        padding-bottom: 1rem;
        font-weight: 500;
        font-size: 1.5rem;
        span{
          color: $title-color;
          padding: 0 0.5rem;
          border-radius: 0.4rem;

        }
      }
    }
    .left{
      display: flex;
      img{
        width: 10rem;
        height: 10rem;
        border-radius: 7%;
        margin-right: 2rem;
      }
      h1{
        font-size: 2.1rem;
        font-weight: 600;
        color: $title-color;
        margin-bottom: 0.4rem;
        span{
          font-weight: 400;
          color: $text-color;
        }
      }
      h2{
        font-size: 1.85rem;
        font-weight: 700;
        color: $sub-text-color;
      }
      .tags{
        margin-top: 1rem;
      }
      .deps{
        margin-top: 0.6rem;
      }
    }
  }
  .content{
    grid-area: 2 / 1 / 3 / 2;
    border-radius: 15px 15px 0 0;
    background-color: $tertiary-color;

    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 1rem;
      border-bottom: 1px solid $border-color;
      input{
        width: 20%;
        padding: 0.5rem;
        border-radius: 8px;
        border: 3px solid $border-color;
        background-color: $secondary-color;
        color: $text-color;
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
      }
      ul{
        padding: 1rem 0;
        color: $text-color;
        display: flex;
        align-items: stretch;
        width: 100%;
        li{
          display: flex;
          align-items: center;
          height: 100%;
          margin: 0 0 0 2rem;
          transition: all 0.2s, color 0.4s ease-in-out, border-bottom-color 0.4s ease-in-out;
          font-weight: 400;
          font-size: 1rem;
          cursor: pointer;
          text-transform: uppercase;
          border-bottom: 1px solid transparent;
          &.selected{
            color: $text-color;
            border-bottom: 1px solid $accent-color;
          }
          &:hover{
            color: $accent-color;
          }
        }
      }
    }
  }
}

@media (max-width: 600px){
  .container{
    display: none;
  }
  .no-responsive{
    display: block;
  }
}
</style>