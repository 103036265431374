import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from "vuex";
import router from './router'
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {
        loggedIn: false,
        user: {},
        token: '',
        tags: [],
        userid: null,
    },
    mutations: {
        login(state, user) {
            console.log('login');
            state.loggedIn = true;
            state.user = user;
            console.log(state.loggedIn);
        },
        logout(state) {
            state.loggedIn = false;
            state.user = {};
            state.token = '';
        },
        setToken(state, token) {
            state.token = token;
        },
        setTags(state, tags) {
            state.tags = tags;
        },
        setUserid(state, userid) {
            state.userid = userid;
        }
    },
    plugins: [createPersistedState()]
})


createApp(App)
  .use(router)
  .use(store)
  .mount('#app')
